/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';

import Logo from '../images/logo.png';

export const NavLink = ({ link, children, handleLinkClick }) => (
  <Link
    to={link}
    onClick={handleLinkClick}
    className="relative block px-8 py-6 text-xs font-black tracking-widest uppercase bg-white border-b group hover:bg-gray-100 lg:border-transparent lg:hover:border-brand-blue lg:border-b-4 lg:py-8 hover:text-gray-800 lg:text-center"
  >
    {children}
  </Link>
);

NavLink.propTypes = {
  link: PropTypes.string,
  children: PropTypes.any,
  handleLinkClick: PropTypes.func,
};

export const SubMenu = ({ link, children }) => (
  <li>
    <Link
      to={link}
      className="flex w-64 px-8 py-6 leading-tight text-left bg-white hover:bg-gray-100 hover:shadow-inner"
    >
      {children}
    </Link>
  </li>
);

SubMenu.propTypes = {
  link: PropTypes.string,
  children: PropTypes.any,
};

const Header = ({ setMenuIsOpen }) => {
  const [isOpen, setIsOpen] = useState(false);

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const handleClick = () => {
    setIsOpen(!isOpen);
    setMenuIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
    setMenuIsOpen(false);
  };
  return (
    <header
      className={`fixed top-0 z-50 items-center justify-between w-full leading-none bg-white shadow lg:flex lg:h-auto lg:overflow-y-visible ${
        isOpen ? 'h-screen overflow-y-auto' : ''
      }`}
    >
      <div className="relative">
        <div className="flex justify-between w-full">
          <Link
            onClick={handleLinkClick}
            to="/"
            className="flex items-center flex-shrink-0 px-4 text-lg font-bold tracking-tight no-underline"
          >
            <img
              className="h-16"
              src={Logo}
              alt={data.site.siteMetadata.title}
            />
          </Link>

          <button
            className="flex items-center justify-center p-4 lg:hidden"
            onClick={handleClick}
            type="button"
          >
            <span className="px-4 py-3 border rounded shadow hover:shadow-inner">
              <svg
                className="w-4 h-4 fill-current"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <nav
        id="nav"
        className={`${
          isOpen ? 'block' : 'hidden'
        } lg:flex lg:items-center lg:justify-end max-w-6xl relative w-full lg:w-auto border-t lg:border-t-0`}
      >
        <NavLink
          handleLinkClick={handleLinkClick}
          link="/#our-story"
          children="Our&nbsp;Story"
        />

        <div className="relative block text-xs font-black tracking-widest uppercase bg-white border-b group hover:bg-gray-100 lg:border-transparent lg:hover:border-brand-blue lg:border-b-4 hover:text-gray-800 lg:text-center">
          <div className="items-center justify-between lg:flex">
            <Link
              onClick={handleLinkClick}
              to="/#services"
              className="flex px-8 py-6 lg:py-8"
            >
              Services <FaAngleDown className="hidden ml-2 text-sm lg:block" />
            </Link>

            <ul className="inset-0 transform lg:translate-y-full lg:absolute lg:hidden lg:group-hover:block">
              {/* <li className="w-full">
                <Link
                  className="flex px-8 py-6 leading-tight text-left bg-white border-t border-l border-r lg:w-64 hover:bg-gray-100 hover:shadow-inner"
                  to="/venue-sign-in-system"
                >
                  Venue Sign in System
                </Link>
              </li> */}
              <li className="w-full">
                <Link
                  className="flex px-8 py-6 leading-tight text-left bg-white border-t border-l border-r lg:w-64 hover:bg-gray-100 hover:shadow-inner"
                  to="/game-room-design"
                >
                  Game Room Design
                </Link>
              </li>
              <li className="w-full">
                <Link
                  className="flex px-8 py-6 leading-tight text-left bg-white border-t border-l border-r lg:w-64 hover:bg-gray-100 hover:shadow-inner"
                  to="/gmba"
                >
                  GAMING MACHINE BASES
                </Link>
              </li>
              <li className="w-full">
                <div className="flex leading-tight text-left lg:w-64 submenu">
                  <Link to="/cash-handling-equipment" className="w-full">
                    <div className="flex items-center justify-between px-8 py-6 bg-white border-t border-b-0 border-l border-r rounded-bl lg:border-b lg:w-64 hover:bg-gray-100 hover:shadow-inner">
                      Cash Handling Equipment{' '}
                      <FaAngleRight className="hidden ml-2 text-sm lg:block" />
                    </div>
                  </Link>
                  <ul
                    className="relative hidden border rounded-b rounded-tr lg:submenu-hover:block"
                    style={{ left: `-1px` }}
                  >
                    <SubMenu link="/cash-handling-equipment/2-pocket-note-counter">
                      MIB-5 Two Pocket Note Counter
                    </SubMenu>
                    <SubMenu link="/cash-handling-equipment/mixed-note-value-sorter">
                      MIB-9 Mixed Note Value Counter And Sorter
                    </SubMenu>
                    <SubMenu link="/cash-handling-equipment/2-pocket-counter-sorter">
                      MIB-11 Two Pocket Mixed Note Value Counter And Sorter
                    </SubMenu>
                    <SubMenu link="/cash-handling-equipment/sb-3000">
                      SB-3000 3 Pocket Sorter
                    </SubMenu>
                    <SubMenu link="/cash-handling-equipment/smart-clear">
                      SmartClear Note Clearance System
                    </SubMenu>
                    <SubMenu link="/cash-handling-equipment/multi-pocket-fitness-sorter">
                      PA9500 Multi-Pocket Fitness Sorter
                    </SubMenu>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <NavLink
          link="/#testimonials"
          children="Testimonials"
          handleLinkClick={handleLinkClick}
        />
        <NavLink
          link="/#employment"
          handleLinkClick={handleLinkClick}
          children="Employment"
        />
        <NavLink link="/contact-us" children="Contact" />
      </nav>
    </header>
  );
};
Header.propTypes = {
  setMenuIsOpen: PropTypes.func,
};
export default Header;
