import * as React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Footer from './Footer';

import '../css/tailwind.css';

const Layout = ({ children, homePage }) => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  return (
    <div
      className={`flex flex-col min-h-screen font-sans antialiased leading-relaxed text-gray-700 ${
        menuIsOpen
          ? 'lg:h-auto lg:overflow-y-auto overflow-y-hidden h-screen'
          : ''
      }`}
    >
      <Header homePage={homePage} setMenuIsOpen={setMenuIsOpen} />
      <main className="flex flex-col flex-1 w-full mx-auto bg-white">
        {children}
      </main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  homePage: PropTypes.bool,
};

export default Layout;
