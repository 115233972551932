import React from 'react';

const Footer = () => (
  <footer className="w-full mx-auto">
    <p className="flex flex-wrap items-center justify-center w-full p-8 mb-0 text-sm leading-none text-center text-gray-700">
      <span className="w-full mb-4 flex-no-shrink sm:mb-0 sm:w-auto">
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex px-1 font-bold"
          href="https://pd.digital"
        >
          Designed and developed by PD Digital
        </a>
      </span>
      <span className="hidden w-full mx-2 sm:block sm:w-auto">|</span>
      <span className="w-full flex-no-shrink sm:w-auto">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.writebykate.com.au"
        >
          Words by <span className="font-bold">Kate Dwyer</span>
        </a>
      </span>
    </p>
  </footer>
);

export default Footer;
